import {Location} from 'react-router-dom';
import CreateNATSConnection from 'src/runtime/nats';
import GA4React from 'ga-4-react';
import {routing} from '..';

let isFirstLocationEvent = true;
const ga4react = new GA4React('G-F0Z2SXCL1K');

export async function initGoogleAnalytics() {
    await ga4react.initialize();
}

function sendGoogleAnalyticsEvent(loc: any) {
    if (IS_PRODUCTION) {
        if (GA4React.isInitialized()) {
            ga4react.pageview(loc.pathname + loc.search);
        }
    }
}

export default function OnLocationChange(location: Location) {
    const current_path = location.pathname + location.search;
    if (!isFirstLocationEvent) {
        // for the first connection page-views event handled by the server(to avoid missing it if client clouldn't load JS bundles)
        // TODO only_lading: CreateNATSConnection().PushToSubject(`users.server.tracking`, 'page-views', {
        // TODO only_lading:     path: current_path,
        // TODO only_lading: });
    }

    if (isFirstLocationEvent) {
        isFirstLocationEvent = false;
    }

    sendGoogleAnalyticsEvent(location);

    if (routing.__current_path !== current_path) {
        routing.__prev_path = routing.__current_path;
        routing.__current_path = current_path;
    }
}
