import {BasePage, FlattenPages, Page} from 'src/runtime/router/model';
// pages
import LandingPage from 'src/jsx/landing/Landing';
// TODO only_lading:
/*
import {lazy} from 'react';
import TermsPage from 'src/jsx/terms/terms';
import PrivacyPage from 'src/jsx/privacy/privacy';
import ContactPage from 'src/jsx/contact/contact';
import PricingPage from 'src/jsx/pricing/pricing';
import BlogPage from 'src/jsx/blog/blogPage';
import BlogList from 'src/jsx/blog/blog';
import BlogSingle from 'src/jsx/blog/blogSingle';
import BlogCreate from 'src/jsx/blog/blogCreate';
// auth
import AuthPage from 'src/jsx/auth/AuthPage';
import ResetPasswordPage from 'src/jsx/auth/ResetPasswordPage';
import SignInPage from 'src/jsx/auth/SignInPage';
import SignUpPage from 'src/jsx/auth/SignUpPage';
import SignUpProfilePage from 'src/jsx/auth/SignUpProfilePage';
import SignUpVerificationPage from 'src/jsx/auth/SignUpVerificationPage';
// account
import AccountPageRouter from 'src/jsx/account/AccountPageRouter';
// internal
import ButtonsDemoPage from 'src/jsx/internal/ButtonsDemoPage';
import DebugPage from 'src/jsx/internal/debug';
// dashboard
import DashboardProjectPage from 'src/jsx/dashboard/DashboardProjectPage';
import DashboardLandingPage from 'src/jsx/dashboard/pages/DashboardLandingPage';
import DashboardModelOutputPage from 'src/jsx/dashboard/pages/ModelOutput/DashboardModelOutputPage';
import ProjectChatPage from 'src/jsx/dashboard/pages/GeneralChat/ProjectChatPage';
import DashboardOnboarding from 'src/jsx/dashboard/pages/Onboarding/OnboardingPage';
import DashboardSingleChartPage from 'src/jsx/dashboard/pages/SingleChart/DashboardSingleChartPage';
import DashboardProjectSettings from 'src/jsx/dashboard/pages/ProjectSettings/DashboardProjectSettings';
// console
const ConsolePage = lazy(() => import('src/jsx/console/ConsolePage'));
const ConsoleAdminsPage = lazy(() => import('src/jsx/console/pages/ConsoleAdminsPage'));
const ConsoleLandingPage = lazy(() => import('src/jsx/console/pages/ConsoleLandingPage'));
const ConsoleUsersPage = lazy(() => import('src/jsx/console/pages/ConsoleUsersPage'));
const ConsoleSiteMapsPage = lazy(() => import('src/jsx/console/pages/ConsoleSiteMapsPage'));
const ConsoleProjectsPage = lazy(() => import('src/jsx/console/pages/ConsoleProjectsPage'));
const ConsoleProjectPage = lazy(() => import('src/jsx/console/pages/ConsoleProjectPage'));
const ConsoleProjectChartsPage = lazy(() => import('src/jsx/console/pages/ConsoleProjectChartsPage'));
const IconsPage = lazy(() => import('src/jsx/icons/Icons'));
*/

export const pagelist = {
    landing: {
        path: '',
        title: 'Whitetail AI: Instant Data Insights & Stunning Visual Analytics',
        component: LandingPage,
    },
    // TODO only_lading:
    /*
    auth: {
        path: 'WRAPPER',
        title: 'Login',
        component: AuthPage,
        children: {
            signUpEmployer: {
                path: 'sign-up',
                title: 'Sign Up | whitetail.ai',
                component: SignUpPage,
            },
            signUpVerification: {
                path: 'sign-up/verification',
                title: 'Sign Up | whitetail.ai',
                component: SignUpVerificationPage,
            },
            signUpProfile: {
                path: 'sign-up/profile',
                title: 'Sign Up | whitetail.ai',
                component: SignUpProfilePage,
            },
            resetPassword: {
                path: 'reset-password',
                title: 'Reset Password | whitetail.ai',
                component: ResetPasswordPage,
            },
            signIn: {
                path: 'login',
                title: 'Sign In | whitetail.ai',
                component: SignInPage,
            },
        },
    },
    console: {
        path: 'console',
        title: 'Console',
        component: ConsolePage,
        children: {
            consoleLanding: {
                path: '',
                title: 'Console',
                component: ConsoleLandingPage,
            },
            consoleUsers: {
                path: 'users',
                title: 'Console Users',
                component: ConsoleUsersPage,
            },
            consoleProjects: {
                path: 'projects',
                title: 'Console Projects',
                component: ConsoleProjectsPage,
            },
            consoleProjectPage: {
                path: ['projects/:project_uri'],
                title: 'Console Project',
                component: ConsoleProjectPage,
                children: {
                    consoleProjectCharts: {
                        path: 'charts/:chart_marker?',
                        title: 'Console Project Charts',
                        component: ConsoleProjectChartsPage,
                    },
                },
            },
            consoleAdmins: {
                path: 'admins',
                title: 'Console Admins',
                component: ConsoleAdminsPage,
            },
            consoleSiteMaps: {
                path: 'sitemaps',
                title: 'Console SiteMaps',
                component: ConsoleSiteMapsPage,
            },
        },
    },
    account: {
        path: 'account/*',
        title: 'Account | Whitetail AI',
        component: AccountPageRouter,
        children: {
            security: {
                path: 'security',
                title: 'Login & Security | hive.ing',
                component: AccountPageRouter,
            },
        },
    },
    terms: {
        path: 'terms',
        title: 'Terms of use | Whitetail AI',
        component: TermsPage,
    },
    privacy: {
        path: 'privacy',
        title: 'Privacy Policy | Whitetail AI',
        component: PrivacyPage,
    },
    contact: {
        path: 'contact-us',
        title: 'Contact Us for AI Data Visualization Support | Whitetail AI',
        component: ContactPage,
    },
    pricing: {
        path: 'pricing',
        title: 'Whitetail AI Pricing - Affordable Plans & Packages',
        component: PricingPage,
    },
    blog: {
        path: 'blog',
        title: 'Latest in AI Data Visualization - Insights & Trends | Our Blog',
        component: BlogPage,
        children: {
            blogList: {
                path: '',
                title: 'Latest in AI Data Visualization - Insights & Trends | Our Blog',
                component: BlogList,
            },
            blogCreate: {
                path: 'create/:uri?',
                title: 'Latest in AI Data Visualization - Insights & Trends | Our Blog',
                component: BlogCreate,
            },
            blogSingle: {
                path: ':uri',
                title: 'SET_LATER',
                component: BlogSingle,
            },
        },
    },
    dashboardProject: {
        path: '/ai/:company_uri/:project_uri',
        title: 'Dashboard Project',
        component: DashboardProjectPage,
        children: {
            dashboardLanding: {
                path: '',
                title: 'Dashboard',
                component: DashboardLandingPage,
            },
            dashboardOnboarding: {
                path: 'onboarding',
                title: 'Onboarding',
                component: DashboardOnboarding,
            },
            dashboardChat: {
                path: 'chat',
                title: 'Chat',
                component: ProjectChatPage,
            },
            dashboardModelOutput: {
                path: 'model-output',
                title: 'Model Output',
                component: DashboardModelOutputPage,
            },
            dashboardModelOutputSingleChartPage: {
                path: 'model-output/charts/:chart_marker',
                title: 'Model Output',
                component: DashboardSingleChartPage,
            },
            dashboardProjectSettingsPage: {
                path: 'settings',
                title: 'Settings',
                component: DashboardProjectSettings,
            },
        },
    },
    icons: {
        path: 'icons',
        title: 'Icons',
        component: IconsPage,
    },
    buttons: {
        path: 'buttons',
        title: 'Buttons',
        component: ButtonsDemoPage,
    },
    debug: {
        path: 'debug',
        title: 'Debug',
        component: DebugPage,
    },
    */
} satisfies Record<string, Page<any>>;

// GENERAL TYPES, DO NOT CHANGE
export interface BasePageWithID extends BasePage {
    id: PageIDs;
}

export type PageIDs = FlattenPages<typeof pagelist>;
export type PagelistMap = Record<PageIDs, BasePageWithID>;
export type PageFetcehrsMap = Record<PageIDs, (() => Promise<any>) | undefined>;

function flattenPages(list: PagelistMap, pages: Record<string, Page<any, any>>): PagelistMap {
    Object.entries(pages).forEach(([key, page]) => {
        const pageKey = key as PageIDs;
        if (list[pageKey]) {
            console.error(`Second declaration for page key: [${key}]`);
        }

        list[pageKey] = {
            id: pageKey,
            title: page.title,
            path: page.path,
            caseSensitive: page.caseSensitive,
            settings: page.settings,
        };

        if (page.children) {
            list = flattenPages(list, page.children);
        }
    });

    return list;
}

export const pagemap = flattenPages({} as PagelistMap, pagelist);

export function getPagePreFetchers(list: PageFetcehrsMap, pages: Record<string, Page<any, any>>): PageFetcehrsMap {
    Object.entries(pages).forEach(([key, page]) => {
        const pageKey = key as PageIDs;
        list[pageKey] = page.preFetcher;

        if (page.children) {
            list = getPagePreFetchers(list, page.children);
        }
    });

    return list;
}
